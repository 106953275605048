body {
  margin: 0;
  font-family: 'Didot', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Center spinner */
.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-hover-effect {
  transition: transform 0.5s;
  will-change: transform;
}

.card-hover-effect:hover {
  transform: scale(1.05);
}

